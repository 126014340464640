import { useRef, useEffect, useState } from 'react';

export default function FetchVideoAccueil() {
    const [videoAccueil, setVideoAccueil] = useState([])

    function AutoPlaySilentVideo(props) {
        const videoRef = useRef(undefined);
        useEffect(() => {
            videoRef.current.defaultMuted = true;
        })
        return (
            <video
                className={props.className}
                ref={videoRef}
                loop
                autoPlay
                muted
                playsInline
                style={{
                  positioin: 'absolute',
                  left: '50%',
                  top: '50%',
                  width: '100%',
                  objectFit: 'cover',
                  zIndex: '-1'
                }}>
                <source src={props.video} type="video/mp4"/>
            </video>
        );
    }

    useEffect(() => {
        fetch('https://gestion.contenu.algego.com/wp-json/wp/v2/video-accueil')
            .then(response => response.json())
            .then(data => setVideoAccueil(data))
    }, [])
    return (
        <>
            {videoAccueil.map(vid => {
                const videoSrc = vid.content.rendered.match(/src="(.+?)"/)[1];
                return (
                    // <video className='firstHeadBack' key={vid.id}
                    //     src={videoSrc}
                    //     autoPlay
                    //     loop
                    //     muted
                    //     style={{
                    //         positioin: 'absolute',
                    //         left: '50%',
                    //         top: '50%',
                    //         width: '100%',
                    //         objectFit: 'cover',
                    //         zIndex: '-1'
                    //     }}
                    // >
                    // </video>
                    <AutoPlaySilentVideo video={videoSrc} />
                )
            })}
        </>
    )
}